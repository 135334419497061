'use client';

import { Box } from '@mui/material';

export default function BackgroundAnimation() {
  return (
    <Box
      sx={{
        position: 'absolute',
        inset: 0,
        width: '100%',
        height: '100%',
        zIndex: 0,
        overflow: 'hidden',
      }}
    >
      <svg
        aria-hidden="true"
        className="pointer-events-none"
        fill="none"
        style={{
          position: 'absolute',
          width: '90%',
          height: '80%',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: 'none',
        }}
        viewBox="0 0 1800 800"
        preserveAspectRatio="xMidYMid slice"
      >
        <defs>
          <linearGradient id="spotGradient" gradientUnits="userSpaceOnUse">
            <stop offset="0%" stopColor="#58F0D400" />
            <stop offset="40%" stopColor="#58F0D400" />
            <stop offset="45%" stopColor="#58F0D410" />
            <stop offset="48%" stopColor="#58F0D440" />
            <stop offset="50%" stopColor="#58F0D4" />
            <stop offset="52%" stopColor="#58F0D440" />
            <stop offset="55%" stopColor="#58F0D410" />
            <stop offset="60%" stopColor="#58F0D400" />
            <stop offset="100%" stopColor="#58F0D400" />
            <animateTransform
              attributeName="gradientTransform"
              type="translate"
              from="-400 0"
              to="1600 0"
              dur="8s"
              repeatCount="indefinite"
              additive="sum"
            />
          </linearGradient>
        </defs>
        <path
          className="stream-path"
          d="M250 200h100v500h-100v-500z M350 200h200c100 0 160 50 160 100c0 50-60 100-160 100h-200v-200z M350 480h200c100 0 160 50 160 100c0 50-60 100-160 100h-200v-200z M200 700h420"
          stroke="#E2E8F0"
          strokeWidth="1.5"
          strokeDasharray="4"
          vectorEffect="non-scaling-stroke"
        />
        <path
          className="stream-path"
          d="M900 200l120 500l120-500l120 500l120-500h120l-180 600h-120l-120-400l-120 400h-120l-180-600h120z M700 800h600"
          stroke="#E2E8F0"
          strokeWidth="1.5"
          strokeDasharray="4"
          vectorEffect="non-scaling-stroke"
        />
        <path
          d="M200 200h100v500h-100v-500z M300 200h180c80 0 140 50 140 100c0 50-60 100-140 100h-180v-200z M300 480h180c80 0 140 50 140 100c0 50-60 100-140 100h-180v-200z M200 700h420"
          stroke="url(#spotGradient)"
          strokeWidth="3"
          strokeLinecap="round"
          vectorEffect="non-scaling-stroke"
        />
        <path
          d="M700 200l100 500l100-500l100 500l100-500h100l-150 600h-100l-100-400l-100 400h-100l-150-600h100z M700 800h600"
          stroke="url(#spotGradient)"
          strokeWidth="3"
          strokeLinecap="round"
          vectorEffect="non-scaling-stroke"
        />
      </svg>
    </Box>
  );
}
