'use client';

import { Box, Container, Typography, IconButton, alpha } from '@mui/material';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useRef, useState } from 'react';
import SellerHeader from './SellerHeader';
import ProductCard from '@web/components/product-cards/ProductCard';

interface Product {
  title: string;
  image: string;
  slug: string;
  seller_name: string;
  seller_url: string;
  seller_product_count: number;
  seller_price_trend: number;
}

interface DiscountSectionProps {
  products: {
    [seller: string]: Product[];
  };
}

export default function DiscountSection({ products }: DiscountSectionProps) {
  const [showArrows, setShowArrows] = useState<string | null>(null);
  const [canScrollLeft, setCanScrollLeft] = useState<{
    [key: string]: boolean;
  }>({});
  const [canScrollRight, setCanScrollRight] = useState<{
    [key: string]: boolean;
  }>({});

  const scrollContainerRefs = useRef<{ [key: string]: HTMLDivElement | null }>(
    {}
  );

  const checkScroll = (sellerId: string) => {
    const container = scrollContainerRefs.current[sellerId];
    if (!container) return;

    setCanScrollLeft((prev) => ({
      ...prev,
      [sellerId]: container.scrollLeft > 0,
    }));

    setCanScrollRight((prev) => ({
      ...prev,
      [sellerId]:
        container.scrollLeft < container.scrollWidth - container.clientWidth,
    }));
  };

  const handleScroll = (sellerId: string, direction: 'left' | 'right') => {
    const container = scrollContainerRefs.current[sellerId];
    if (!container) return;

    const scrollAmount = 440;
    const targetScroll =
      container.scrollLeft +
      (direction === 'left' ? -scrollAmount : scrollAmount);
    container.scrollTo({ left: targetScroll, behavior: 'smooth' });
  };

  // Update scroll buttons visibility on scroll
  const handleScrollEvent = (sellerId: string) => {
    checkScroll(sellerId);
  };

  // Check initial scroll state when hovering
  const handleMouseEnter = (sellerId: string) => {
    setShowArrows(sellerId);
    checkScroll(sellerId);
  };

  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1.5,
          py: 2,
        }}
      >
        <TrendingDownIcon
          sx={{
            color: 'success.main',
            fontSize: '2rem',
          }}
        />
        <Typography
          variant="h5"
          component="h2"
          sx={{
            fontSize: { xs: '1.25rem', sm: '1.5rem' },
            fontWeight: 700,
            fontFamily: '"Cal Sans", "Plus Jakarta Sans", sans-serif',
            WebkitBackgroundClip: 'text',
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}
        >
          Today's Best Deals
          <LocalOfferIcon
            sx={{
              color: 'error.main',
              fontSize: '1.25rem',
              transform: 'rotate(90deg)',
            }}
          />
        </Typography>
      </Box>

      {Object.entries(products).map(([seller, sellerProducts]) => {
        const firstProduct = sellerProducts[0];

        return (
          <Box
            key={seller}
            sx={{
              mb: 5,
              p: { xs: 1, md: 2 },
              background: 'linear-gradient(to bottom, #FFFFFF, #FAFBFF)',
              borderRadius: '1rem',
              boxShadow:
                '0 1px 2px rgba(0,0,0,0.04), 0 2px 4px rgba(0,0,0,0.02)',
              '&:hover': {
                boxShadow:
                  '0 4px 6px rgba(0,0,0,0.04), 0 2px 4px rgba(0,0,0,0.02)',
              },
              transition: 'box-shadow 0.2s ease-in-out',
              position: 'relative',
            }}
            onMouseEnter={() => handleMouseEnter(seller)}
            onMouseLeave={() => setShowArrows(null)}
          >
            <SellerHeader
              seller={seller}
              url={firstProduct.seller_url}
              productCount={firstProduct.seller_product_count}
              priceTrend={firstProduct.seller_price_trend}
            />

            <Box sx={{ position: 'relative' }}>
              {showArrows === seller && (
                <>
                  {canScrollLeft[seller] && (
                    <IconButton
                      onClick={() => handleScroll(seller, 'left')}
                      sx={{
                        position: 'absolute',
                        left: { xs: 0, md: 8 },
                        top: '50%',
                        transform: 'translateY(-50%)',
                        bgcolor: 'background.paper',
                        background:
                          'linear-gradient(to right, rgba(255,255,255,0.95), rgba(255,255,255,0.8))',
                        backdropFilter: 'blur(4px)',
                        boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                        width: 56,
                        height: 56,
                        '&:hover': {
                          background:
                            'linear-gradient(to right, rgba(255,255,255,0.98), rgba(255,255,255,0.9))',
                          boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
                        },
                        zIndex: 2,
                      }}
                    >
                      <ArrowBackIcon
                        sx={{ fontSize: 28, color: 'text.primary' }}
                      />
                    </IconButton>
                  )}
                  {canScrollRight[seller] && (
                    <IconButton
                      onClick={() => handleScroll(seller, 'right')}
                      sx={{
                        position: 'absolute',
                        right: { xs: 0, md: 8 },
                        top: '50%',
                        transform: 'translateY(-50%)',
                        bgcolor: 'background.paper',
                        background:
                          'linear-gradient(to left, rgba(255,255,255,0.95), rgba(255,255,255,0.8))',
                        backdropFilter: 'blur(4px)',
                        boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                        width: 56,
                        height: 56,
                        '&:hover': {
                          background:
                            'linear-gradient(to left, rgba(255,255,255,0.98), rgba(255,255,255,0.9))',
                          boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
                        },
                        zIndex: 2,
                      }}
                    >
                      <ArrowForwardIcon
                        sx={{ fontSize: 28, color: 'text.primary' }}
                      />
                    </IconButton>
                  )}
                </>
              )}

              <Box
                ref={(el: HTMLDivElement | null) => {
                  scrollContainerRefs.current[seller] = el;
                }}
                onScroll={() => handleScrollEvent(seller)}
                sx={{
                  display: 'flex',
                  gap: 2,
                  overflowX: 'auto',
                  pb: 2,
                  px: { xs: 1, md: 2 },
                  scrollbarWidth: 'none', // Firefox
                  '::-webkit-scrollbar': {
                    display: 'none', // Chrome, Safari, Edge
                  },
                  msOverflowStyle: 'none', // IE
                }}
              >
                {sellerProducts.map((product) => (
                  <Box
                    key={product.slug}
                    sx={{
                      minWidth: 220,
                      maxWidth: 220,
                    }}
                  >
                    <ProductCard product={product} />
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        );
      })}
    </Container>
  );
}
