'use client';

import { useEffect } from 'react';

export default function HomeStructuredData() {
  useEffect(() => {
    const structuredData = {
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      name: 'BuyWisely',
      url: process.env.NEXT_PUBLIC_SITE_URL || 'https://buywisely.com.au',
      description:
        'Track price history, compare prices, and set price alerts for products across 20,000+ online shops. BuyWisely helps you find the best deals and make informed buying decisions.',
      potentialAction: {
        '@type': 'SearchAction',
        target: {
          '@type': 'EntryPoint',
          urlTemplate: `${process.env.NEXT_PUBLIC_SITE_URL}/product/search?q={search_term_string}`,
        },
        'query-input': 'required name=search_term_string',
      },
    };

    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.text = JSON.stringify(structuredData);
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return null;
}
