'use client';

import { Box, Typography } from '@mui/material';
import SellerImage from '@web/components/SellerImage';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import DumbLink from '@web/components/DumbLink';

interface SellerHeaderProps {
  seller: string;
  url: string;
  productCount: number;
  priceTrend: number;
}

export default function SellerHeader({
  seller,
  url,
  productCount,
  priceTrend,
}: SellerHeaderProps) {
  const formattedTrend = priceTrend.toFixed(4);

  // Mobile layout
  const mobileLayout = (
    <Box sx={{ flex: 1 }}>
      <Typography
        variant="subtitle1"
        sx={{
          fontWeight: 600,
          color: 'text.primary',
          mb: 0.5,
        }}
      >
        {seller}
      </Typography>

      <Box
        sx={{
          display: 'flex',
          gap: 2,
          flexWrap: 'nowrap',
          fontSize: '0.875rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
            whiteSpace: 'nowrap',
          }}
        >
          <LocalMallIcon
            sx={{ color: 'text.secondary', fontSize: 'inherit' }}
          />
          <Typography
            variant="body2"
            sx={{ color: 'text.secondary', fontWeight: 500 }}
          >
            {productCount.toLocaleString()} products
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
            whiteSpace: 'nowrap',
          }}
        >
          {priceTrend > 0 ? (
            <TrendingUpIcon sx={{ color: 'error.main', fontSize: 'inherit' }} />
          ) : (
            <TrendingDownIcon
              sx={{ color: 'success.main', fontSize: 'inherit' }}
            />
          )}
          <Typography
            variant="body2"
            sx={{
              color: priceTrend > 0 ? 'error.main' : 'success.main',
              fontWeight: 500,
            }}
          >
            {priceTrend > 0 ? '+' : ''} {formattedTrend}% price
          </Typography>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 2,
        px: { xs: 1, md: 2 },
      }}
    >
      {/* Show different layouts based on screen size */}
      <Box
        sx={{ display: { xs: 'flex', md: 'none' }, height: 48, width: '100%' }}
      >
        <DumbLink href={`/shop/${seller}`}>
          <SellerImage seller_product_url={url} height={48} />
        </DumbLink>
        {mobileLayout}
      </Box>

      <Box
        sx={{
          display: { xs: 'none', md: 'flex' },
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', height: 48, alignItems: 'center', gap: 2 }}>
          <DumbLink href={`/shop/${seller}`}>
            <SellerImage seller_product_url={url} height={48} />
          </DumbLink>
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: 600,
              color: 'text.primary',
              display: { xs: 'none', sm: 'block' },
            }}
          >
            <DumbLink
              href={`/shop/${seller}`}
              style={{
                textDecoration: 'none',
                color: 'inherit',
              }}
            >
              {seller}
            </DumbLink>
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 3,
            fontSize: '1rem',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 0.5,
              whiteSpace: 'nowrap',
            }}
          >
            <LocalMallIcon
              sx={{ color: 'text.secondary', fontSize: 'inherit' }}
            />
            <Typography
              variant="body2"
              sx={{ color: 'text.secondary', fontWeight: 500 }}
            >
              {productCount.toLocaleString()} products
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 0.5,
              whiteSpace: 'nowrap',
            }}
          >
            {priceTrend > 0 ? (
              <TrendingUpIcon
                sx={{ color: 'error.main', fontSize: 'inherit' }}
              />
            ) : (
              <TrendingDownIcon
                sx={{ color: 'success.main', fontSize: 'inherit' }}
              />
            )}
            <Typography
              variant="body2"
              sx={{
                color: priceTrend > 0 ? 'error.main' : 'success.main',
                fontWeight: 500,
              }}
            >
              {formattedTrend}% price {priceTrend > 0 ? 'increase' : 'drop'}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
