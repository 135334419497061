import { memo, useMemo } from 'react';
import {
  Box,
  Button,
  Card,
  Chip,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { H4, Small } from '../Typography';
import BazaarRating from '../BazaarRating';
import { FlexBox } from '../flex-box';
import {
  currencyFormatter,
  numberFormatter,
  percentFormatter,
} from '@web/utils/currency';
import NextImage from 'next/image';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import useProductCollection from '@web/useProductionCollection';
import DumbLink from '@web/components/DumbLink';
import Link from 'next/link';
import SellerImage from '@web/components/SellerImage';
import OptimizedImage from '@web/components/OptimizedImage';

const StyledBazaarCard = ({ children, ...rest }) => {
  return (
    <Card
      {...rest}
      sx={{
        height: '100%',
        margin: 'auto',
        display: 'flex',
        overflow: 'hidden',
        borderRadius: '8px',
        position: 'relative',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        transition: 'all 250ms ease-in-out',
        ':hover': {
          boxShadow: (theme) => theme.shadows[3],
          '& .hover-box': {
            opacity: 1,
          },
        },
      }}
    >
      {children}
    </Card>
  );
};

const ImageWrapper = ({
  children,
  product,
  handleCollectionClick,
  isProductAdded,
  ...rest
}) => {
  return (
    <Box
      {...rest}
      sx={(theme) => ({
        textAlign: 'center',
        position: 'relative',
        display: 'inline-block',
        '&:hover img': {
          filter: 'brightness(1.1)',
        },
        [theme.breakpoints.down('sm')]: {
          display: 'block',
        },
      })}
    >
      {product.discount > 0.01 && (
        <StyledChip
          color="primary"
          size="small"
          label={`- ${percentFormatter.format(product.discount)}`}
        />
      )}
      <StyledFavoriteIcon
        onClick={handleCollectionClick}
        added={isProductAdded}
      />
      <Box
        sx={{
          '& img': {
            transition: 'transform 0.3s ease',
          },
          '&:hover img': {
            transform: 'scale(1.05)',
          },
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

const StyledChip = ({ children, ...rest }) => {
  return (
    <Chip
      {...rest}
      sx={{
        position: 'absolute',
        top: '10px',
        left: '10px',
        fontWeight: 800,
        fontSize: '12px',
        pointerEvents: 'none',
        zIndex: 1,
      }}
    >
      {children}
    </Chip>
  );
};

const StyledFavoriteIcon = ({ added, ...rest }) => {
  return (
    <IconButton
      {...rest}
      sx={{
        position: 'absolute',
        top: '2px',
        right: '1px',
        zIndex: 1,
      }}
    >
      {added ? (
        <FavoriteIcon color="primary" sx={{ fontSize: 26 }} />
      ) : (
        <FavoriteBorderIcon sx={{ fontSize: 26 }} />
      )}
    </IconButton>
  );
};

const ContentWrapper = ({ children, ...rest }) => {
  return (
    <Box
      {...rest}
      sx={{
        padding: '1rem',
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        '& .title, & .categories': {
          display: '-webkit-box',
          WebkitLineClamp: '2',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          minHeight: '3em',
          em: {
            color: 'primary.main',
          },
        },
        '& .oz_title': {
          display: '-webkit-box',
          WebkitLineClamp: '4',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          minHeight: '3em',
        },
      }}
    >
      {children}
    </Box>
  );
};

const Ratings = memo(({ reviews_cnt, rating }) => (
  <FlexBox alignItems="center" gap={1} my={0.5}>
    <BazaarRating
      color="warn"
      fontSize="1rem"
      value={Number(rating) || 0}
      precision={0.1}
      readOnly
    />
    <Small lineHeight="1">{numberFormatter.format(reviews_cnt)}</Small>
  </FlexBox>
));
Ratings.displayName = 'Ratings';
const SellerInfo = memo(({ seller_url, seller_name }) => (
  <FlexBox alignItems="center" gap={1} mb={0.5}>
    <SellerImage seller_product_url={seller_url} height={30} width={30} />
    <Typography noWrap>{seller_name}</Typography>
  </FlexBox>
));
SellerInfo.displayName = 'SellerInfo';
const ProductCard = ({ product }) => {
  let reviews_count = product.reviews_count;
  let reviews_rating = product.reviews_rating;
  if (product.hasOwnProperty('reviews_cnt')) {
    reviews_count = product.reviews_cnt;
    reviews_rating = product.rating;
  }
  const { isProductAdded, handleCollectionClick } =
    useProductCollection(product);
  const priceRange =
    product.lowest_price &&
    product.highest_price &&
    `${currencyFormatter.format(
      product.lowest_price
    )} - ${currencyFormatter.format(product.highest_price)}`;

  const ProductImage = useMemo(
    () =>
      product.image && (
        <OptimizedImage
          src={product.image}
          width={300}
          height={200}
          alt={product.title}
          style={{
            width: '100%',
            height: 'auto',
            objectFit: 'contain',
            aspectRatio: '1.5/1',
            transition: 'filter 0.3s ease',
          }}
          className="product-image"
        />
      ),
    [product.image]
  );

  const productLink = product.slug
    ? `/product/${product.slug}`
    : product.url
    ? product.url
    : '/';
  return (
    <StyledBazaarCard>
      <ImageWrapper
        product={product}
        handleCollectionClick={handleCollectionClick}
        isProductAdded={isProductAdded}
      >
        <DumbLink href={productLink} target={product.slug ? '_self' : '_blank'}>
          {ProductImage}
        </DumbLink>
      </ImageWrapper>

      <ContentWrapper>
        <Box>
          <DumbLink
            href={productLink}
            target={product.slug ? '_self' : '_blank'}
          >
            <Typography
              fontSize="14px"
              fontWeight="600"
              className="title"
              color="text.secondary"
              dangerouslySetInnerHTML={{ __html: product.title }}
            ></Typography>
          </DumbLink>
          {product.previous_price ? (
            <Stack>
              <FlexBox alignItems="center" my={0.5}>
                <H4 fontWeight="600" color="primary.main" mr={1}>
                  {currencyFormatter.format(product.lowest_price)}
                </H4>
                <Box color="grey.600" fontWeight="600">
                  <del>{currencyFormatter.format(product.previous_price)}</del>
                </Box>
              </FlexBox>
            </Stack>
          ) : (
            <Typography color="primary.main" noWrap fontWeight={600}>
              {product.offer_amount > 1
                ? priceRange
                : currencyFormatter.format(product.lowest_price)}
            </Typography>
          )}
        </Box>

        {reviews_count > 0 ? (
          <Ratings reviews_cnt={reviews_count} rating={reviews_rating} />
        ) : (
          <Box
            sx={{
              minHeight: product.original_price ? 0 : 16,
            }}
          ></Box>
        )}
        {product.seller_name && product.page_type === 'hot' && (
          <SellerInfo
            seller_url={product.seller_url && `https://${product.seller_url}`}
            seller_name={product.seller_name}
          />
        )}
        {product.offer_amount > 0 && priceRange && (
          <Button
            variant="contained"
            fullWidth
            size="small"
            color="primary"
            component={DumbLink}
            href={productLink}
            target={product.slug ? '_self' : '_blank'}
          >
            <Typography fontWeight={600} fontSize={13} noWrap>
              {product.offer_amount > 1
                ? `Compare ${product.offer_amount} offers`
                : `From ${product.seller_name}`}
            </Typography>
          </Button>
        )}
        {product.node && (
          <Stack
            direction="row"
            gap={1}
            alignItems="center"
            mt={1}
            component={Link}
            href={`https://www.ozbargain.com.au/node/${product.node}?utm_source=buywisely`}
            target="_blank"
          >
            <Stack alignItems="center" gap={1}>
              <NextImage
                src={`/images/oz.webp`}
                width={50}
                height={30}
                alt="ozbargain"
                style={{ objectFit: 'contain' }}
              />
              <Stack direction="row" gap={1} alignItems="center">
                <Typography fontWeight="600" color="#4a4">
                  {product.upvotes}
                </Typography>
                <Typography fontWeight="600" color="#c30">
                  {product.downvotes}
                </Typography>
              </Stack>
              {product.status && (
                <Chip
                  label={product.status}
                  size="small"
                  color="error"
                  sx={{ fontWeight: 700 }}
                />
              )}
            </Stack>

            <Typography
              fontSize="14px"
              fontWeight="600"
              className="oz_title"
              color="text.secondary"
            >
              {product.oz_title}
            </Typography>
          </Stack>
        )}
      </ContentWrapper>
    </StyledBazaarCard>
  );
};
export default memo(ProductCard);
