'use client';

import { Box, Container, Typography } from '@mui/material';
import Search from '@web/components/layouts/Search';
import BackgroundAnimation from './BackgroundAnimation';

interface HeroSectionProps {
  stats: {
    totalProducts: number;
    totalOffers: number;
    priceChangePercent: number;
    lastUpdated: Date;
    totalSellers: number;
  } | null;
}

export default function HeroSection({ stats }: HeroSectionProps) {
  if (!stats) return null;

  return (
    <Box
      sx={{
        background: 'linear-gradient(145deg, #FFFFFF 0%, #F5F9FF 100%)',
        pt: { xs: 1, sm: 2, md: 4 },
        pb: { xs: 0.5, sm: 1.5, md: 3 },
        textAlign: 'center',
        position: 'relative',
        minHeight: '22vh',
        display: 'flex',
        alignItems: 'center',
        boxShadow:
          '0 6px 16px -6px rgba(0, 0, 0, 0.08), 0 4px 8px -4px rgba(0, 0, 0, 0.03)',
        borderBottom: '1px solid rgba(0, 0, 0, 0.04)',
        '&::before': {
          content: '""',
          position: 'absolute',
          inset: 0,
          opacity: 0.5,
          backgroundImage: `
            radial-gradient(circle at 0% 0%, rgba(99, 102, 241, 0.12) 0%, transparent 50%),
            radial-gradient(circle at 100% 100%, rgba(14, 165, 233, 0.12) 0%, transparent 50%)
          `,
          filter: 'blur(80px)',
          pointerEvents: 'none',
        },
      }}
    >
      <BackgroundAnimation />
      <Container
        maxWidth="md"
        sx={{
          position: 'relative',
          zIndex: 100,
          py: { xs: 0.5, sm: 1, md: 2 },
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: { xs: '1.75rem', sm: '2.25rem', md: '3rem' },
            fontWeight: 800,
            mb: { xs: 0.75, sm: 1, md: 1.5 },
            fontFamily:
              '"Inter", -apple-system, BlinkMacSystemFont, sans-serif',
            letterSpacing: '-0.02em',
            lineHeight: 1.1,
            position: 'relative',
          }}
        >
          Compare{' '}
          <Box component="span" sx={{ color: 'error.main' }}>
            More.
          </Box>
          <br />
          Pay{' '}
          <Box component="span" sx={{ color: 'success.main' }}>
            Less
          </Box>
        </Typography>

        <Typography
          variant="h5"
          sx={{
            my: { xs: 2, md: 3 },
            fontSize: { xs: '0.875rem', sm: '1rem', md: '1.25rem' },
            color: 'text.secondary',
            fontWeight: 400,
            fontFamily: '"Inter", sans-serif',
            maxWidth: '42rem',
            mx: 'auto',
            lineHeight: 1.4,
            position: 'relative',
          }}
        >
          BuyWisely compares prices from over{' '}
          <Box component="span" sx={{ color: 'primary.main', fontWeight: 600 }}>
            {stats.totalProducts.toLocaleString()}
          </Box>{' '}
          products across{' '}
          <Box component="span" sx={{ color: 'primary.main', fontWeight: 600 }}>
            {stats.totalSellers.toLocaleString()}
          </Box>{' '}
          online stores, ensuring you always land the best deal!
        </Typography>

        <Box
          sx={{
            position: 'relative',
            minHeight: 56,
            '& .sui-search-box': {
              visibility: 'hidden',
              animation: 'fadeIn 0.3s ease forwards',
              '& .sui-search-box__text-input': {
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2) !important',
                transition: 'box-shadow 0.2s ease',
              },
            },
            '@keyframes fadeIn': {
              from: {
                visibility: 'hidden',
                opacity: 0,
              },
              to: {
                visibility: 'visible',
                opacity: 1,
              },
            },
            display: { xs: 'none', md: 'block' },
          }}
        >
          <Search />
        </Box>
      </Container>
    </Box>
  );
}
