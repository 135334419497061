'use client';

import { Box, Container, Typography, IconButton } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useRef, useState } from 'react';
import ProductCard from '@web/components/product-cards/ProductCard';
import Image from 'next/image';
import DumbLink from '@web/components/DumbLink';

interface Product {
  title: string;
  image: string;
  slug: string;
  oz_title: string;
  node: string;
  upvotes: number;
  downvotes: number;
  status?: string;
}

interface OzBargainSectionProps {
  products: Product[];
}

export default function OzBargainSection({ products }: OzBargainSectionProps) {
  const [showArrows, setShowArrows] = useState(false);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const checkScroll = () => {
    const container = scrollContainerRef.current;
    if (!container) return;

    setCanScrollLeft(container.scrollLeft > 0);
    setCanScrollRight(
      container.scrollLeft < container.scrollWidth - container.clientWidth
    );
  };

  const handleScroll = (direction: 'left' | 'right') => {
    const container = scrollContainerRef.current;
    if (!container) return;

    const scrollAmount = 440;
    const targetScroll =
      container.scrollLeft +
      (direction === 'left' ? -scrollAmount : scrollAmount);
    container.scrollTo({ left: targetScroll, behavior: 'smooth' });
  };

  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          py: 2,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <DumbLink href="/product/ozbargain">
            <Image
              src="/images/oz.webp"
              width={50}
              height={30}
              alt="OzBargain"
              style={{ objectFit: 'contain' }}
            />
          </DumbLink>
          <Typography
            variant="h5"
            component="h2"
            sx={{
              fontSize: { xs: '1.25rem', sm: '1.5rem' },
              fontWeight: 700,
              fontFamily: '"Cal Sans", "Plus Jakarta Sans", sans-serif',
              WebkitBackgroundClip: 'text',
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <DumbLink
              href="/product/ozbargain"
              style={{
                textDecoration: 'none',
                color: 'inherit',
              }}
            >
              OzBargain Deals
            </DumbLink>
          </Typography>
        </Box>

        <DumbLink
          href="/product/ozbargain"
          style={{
            textDecoration: 'none',
            display: { xs: 'none', sm: 'block' },
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: 'text.secondary',
              '&:hover': {
                color: 'primary.main',
              },
            }}
          >
            View all deals →
          </Typography>
        </DumbLink>
      </Box>

      <Box
        sx={{
          p: { xs: 1, md: 2 },
          background: 'linear-gradient(to bottom, #FFFFFF, #FAFBFF)',
          borderRadius: '1rem',
          boxShadow: '0 1px 2px rgba(0,0,0,0.04), 0 2px 4px rgba(0,0,0,0.02)',
          '&:hover': {
            boxShadow: '0 4px 6px rgba(0,0,0,0.04), 0 2px 4px rgba(0,0,0,0.02)',
          },
          transition: 'box-shadow 0.2s ease-in-out',
          position: 'relative',
        }}
        onMouseEnter={() => {
          setShowArrows(true);
          checkScroll();
        }}
        onMouseLeave={() => setShowArrows(false)}
      >
        <Box sx={{ position: 'relative' }}>
          {showArrows && (
            <>
              {canScrollLeft && (
                <IconButton
                  onClick={() => handleScroll('left')}
                  sx={{
                    position: 'absolute',
                    left: { xs: 0, md: 8 },
                    top: '50%',
                    transform: 'translateY(-50%)',
                    bgcolor: 'background.paper',
                    background:
                      'linear-gradient(to right, rgba(255,255,255,0.95), rgba(255,255,255,0.8))',
                    backdropFilter: 'blur(4px)',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                    width: 56,
                    height: 56,
                    '&:hover': {
                      background:
                        'linear-gradient(to right, rgba(255,255,255,0.98), rgba(255,255,255,0.9))',
                      boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
                    },
                    zIndex: 2,
                  }}
                >
                  <ArrowBackIcon sx={{ fontSize: 28, color: 'text.primary' }} />
                </IconButton>
              )}
              {canScrollRight && (
                <IconButton
                  onClick={() => handleScroll('right')}
                  sx={{
                    position: 'absolute',
                    right: { xs: 0, md: 8 },
                    top: '50%',
                    transform: 'translateY(-50%)',
                    bgcolor: 'background.paper',
                    background:
                      'linear-gradient(to left, rgba(255,255,255,0.95), rgba(255,255,255,0.8))',
                    backdropFilter: 'blur(4px)',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                    width: 56,
                    height: 56,
                    '&:hover': {
                      background:
                        'linear-gradient(to left, rgba(255,255,255,0.98), rgba(255,255,255,0.9))',
                      boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
                    },
                    zIndex: 2,
                  }}
                >
                  <ArrowForwardIcon
                    sx={{ fontSize: 28, color: 'text.primary' }}
                  />
                </IconButton>
              )}
            </>
          )}

          <Box
            ref={scrollContainerRef}
            onScroll={checkScroll}
            sx={{
              display: 'flex',
              gap: 2,
              overflowX: 'auto',
              px: { xs: 1, md: 2 },
              scrollbarWidth: 'none',
              '::-webkit-scrollbar': {
                display: 'none',
              },
              msOverflowStyle: 'none',
            }}
          >
            {products.map((product) => (
              <Box
                key={product.node}
                sx={{
                  minWidth: 220,
                  maxWidth: 220,
                }}
              >
                <ProductCard product={product} />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
